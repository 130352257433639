<template>
  <div class="auth-wrapper auth-v1 px-2">  
	    <div class="py-2">
		 <b-img
				v-if="UI=='bx'"	
				class="m-2"
				center
				:src="require('@/assets/images/logo/BOXEA-LOGO.png')"
			/>
			<b-img
				v-if="UI=='zx'"	
				class="m-2"
				center
				:src="require('@/assets/images/logo/IZIBOX-LOGO.png')"
			/>
			<b-img
				v-if="UI=='dm'"	
				class="m-2"
				center
				:src="require('@/assets/images/logo/DOMICIALIZ-LOGO.png')"
			/>
			<b-card 
				v-if="etapeencour == 1"	
			>
				<div class="text-center">
					<div class="h1 font-weight-bolder text-secondary my-md-0 ">
					BIENVENUE
					</div>
					<div class="h4 font-weight-normal text-uppercase my-md-0 ">
					chez {{libsoc}}
					</div>
				</div>
				<br>
				<hr>
				<div class="h4 font-weight-bolder text-uppercase text-center">
					Merci de choisir le type de Box
				</div>	
				<div>
					<b-card-group deck>
						<b-card 
							v-for="(tailleitem, UI) in taille" :key="UI"
							bg-variant="light"
							border-variant="secondary"
							class="text-center"
						>
							<b-card-text >
								<hr>
								Le type {{tailleitem.libelle}}
							</b-card-text>
							<b-card-footer>
								<b-button 
									v-on:click="selecttaille(tailleitem)"
									variant="secondary"
									pill
									>
									Je choisir le {{tailleitem.libelle}}
								</b-button>
							</b-card-footer>
						</b-card>
					</b-card-group>
				</div>
				<hr>
			</b-card>
			<div
				v-if="etapeencour == 2"	
			>
				<b-card>
					<div class="text-center">
						<div class="h1 font-weight-bolder text-secondary my-md-0 ">
						Vous avez choisi
						</div>
						<div class="h4 font-weight-normal text-uppercase my-md-0 ">
						 la taille de box <strong>{{libtaille}}</strong>
						</div>
					</div>
				</b-card>
					<div>
  						<b-card-group deck>
							<b-card 
								v-for="(soustailleitem, UI) in soustaille" :key="UI"
								bg-variant="light"
								border-variant="secondary"
								class="text-center"
							>
								<b-card-body>
									<b-img
										center
										fluid
										:src="soustailleitem.image"
									/>
								</b-card-body>
								<b-card-text >
									<hr>
									{{soustailleitem.text}}
								</b-card-text>
								<b-card-footer>
									<b-button 
										v-on:click="selectsoustaille(soustailleitem)"
										variant="secondary"
										pill
										>
										Je choisir le {{soustailleitem.libelle}}
									</b-button>
								</b-card-footer>
							</b-card>
						</b-card-group>
					</div>
				<b-card>
					<b-row offset-md="4" >
						<b-col class="text-left">
							<b-button
								v-ripple.400="'rgba(186, 191, 199, 0.15)'"
								type="reset"
								variant="outline-secondary"
								@click="etapeencour=1"
							>
							  Precedent 
							</b-button>
						</b-col>
						<b-col class="text-right">

						</b-col>
					</b-row>
				</b-card>
			</div>
			<b-card 
				v-if="etapeencour == 3"	
			>
				<div class="text-center">
					<div class="h1 font-weight-bolder text-secondary my-md-0 ">
					Vous avez choisi
					</div>
					<div class="h4 font-weight-normal text-uppercase my-md-0 ">
					 la taille de box <strong>{{libtaille}}</strong>
					</div>
					<div class="h4 font-weight-normal text-uppercase my-md-0 ">
					 le box <strong>{{libsoustaille}}</strong>
					</div>
				</div>
				<hr>
				<b-form @submit.prevent>
					<b-row>
						<b-col cols="3">
						<b-form-checkbox
						  v-model="bsoc"
						  value="1"
						  class="custom-control-primary"
						>
						  Société
						</b-form-checkbox>
						</b-col>
						<b-col cols="9">
							<b-form-group
							  v-if="bsoc"
							  label="Raison sociale *"
							  label-for="hi-rs"
							  label-cols-md="3"
							>
							  <b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
								  <feather-icon icon="HomeIcon" />
								</b-input-group-prepend>
								<b-form-input
									v-model="CRS"
									id="hi-rs"
									placeholder="Raison sociale"
								/>
							  </b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row 	v-if="bsoc">
						<b-col cols="6">
							<b-form-group
							  label="SIRET"
							  label-for="hi-rs"
							  label-cols-md="3"
							>
							  <b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
								</b-input-group-prepend>
								<b-form-input
									v-model="CSIRET"
									id="hi-rs"
									placeholder="N° Siret"
								/>
							  </b-input-group>
							</b-form-group>
						</b-col>
						<b-col cols="6">
							<b-form-group
							  label="N° TVA"
							  label-for="hi-rs"
							  label-cols-md="3"
							>
							  <b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
								</b-input-group-prepend>
								<b-form-input
									v-model="CTVA"
									id="hi-rs"
									placeholder="N° de TVA intracommunautaire"
								/>
							  </b-input-group>
							</b-form-group>
						</b-col>
					</b-row><br>
					<b-row>
						<b-col cols="6">
							<b-form-group
								label="Civilité"
								label-for="hi-civ"
								 label-cols-md="3"
							>
							  <v-select
								id="hi-civ"
								v-model="selectcivilite"
								label="name"
								placeholder="Choisir une civilité"
								:options="civilite"   
							  />
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
					  <b-col cols="6">
						<b-form-group
						  label="Nom"
						  label-for="hi-nom"
						  label-cols-md="3"
						>
						  <b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
							  <feather-icon icon="UserIcon" />
							</b-input-group-prepend>
							<b-form-input
								v-model="CNOM"
								id="hi-nom"
								placeholder="Nom"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					  <b-col cols="6">
						<b-form-group
							label="Prénom"
							label-for="hi-prenom"
							label-cols-md="3"
						>
						  <b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
							  <feather-icon icon="UserIcon" />
							</b-input-group-prepend>
							<b-form-input
								v-model="CPRENOM"
								id="hi-prenom"
								placeholder="Prénom"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					  <b-col cols="6">
						<b-form-group
						  label="Email *"
						  label-for="hi-email"
						  label-cols-md="3"
						>
						  <b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
							  <feather-icon icon="MailIcon" />
							</b-input-group-prepend>
							<b-form-input
								v-model="CEMAIL"
								id="hi-email"
								type="email"
								placeholder="Email"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					  <b-col cols="6">
						<b-form-group
						  label="Mobile *"
						  label-for="hi-number"
						  label-cols-md="3"
						>
						  <b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
							  <feather-icon icon="SmartphoneIcon" />
							</b-input-group-prepend>
							<b-form-input
								v-model="CPORT"
								id="hi-number"
								placeholder="Mobile"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					</b-row>
					* informations obligatoires (Le numéro de portable est indispensable pour la contractualisation et l'accès à votre Box)
					<hr>
					<b-row>
					  <b-col cols="12">
						<b-form-group
						  label="Adresse"
						  label-for="hi-adresse"
						>
						  <b-input-group class="input-group-merge">
							<b-form-input
								v-model="CADRESSE"
								id="hi-adresse"
								placeholder="Adresse"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					</b-row>
					<b-row>
					  <b-col cols="3">
						<b-form-group
						  label="Code postal"
						  label-for="hi-cp"
						>
						  <b-input-group class="input-group-merge">
							<b-form-input
								v-model="CCP"
								id="hi-cp"
								placeholder="Code postal"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					  <b-col cols="4">
						<b-form-group
						  label="Ville"
						  label-for="hi-ville"
						>
						  <b-form-input
							v-model="CVILLE"
							id="hi-ville"
							placeholder="Ville"
							/>
						</b-form-group>
					  </b-col>
					  <b-col cols="5">
						<b-form-group
						  label="Pays"
						  label-for="hi-Pays"
						>
						  <v-select
							id="hi-Pays"
							v-model="selectedpays"
							label="name"
							placeholder="Choisir un pays"
							:options="pays"   
						  />
						</b-form-group>
					  </b-col>
					</b-row>
					
				</b-form>
				<hr>
				<b-row offset-md="4" >
					<b-col class="text-left">
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							type="reset"
							variant="outline-secondary"
							@click="etapeencour=2"
						>
						  Precedent 
						</b-button>
					</b-col>
					<b-col class="text-right">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							type="submit"
							variant="primary"
							class="mr-1"
							@click="goetape4"
						>
						  Voir votre devis
						</b-button>
					</b-col>
				</b-row>
			</b-card>
			<b-card 
				v-if="etapeencour == 4"	
			>
				<div class="text-center">
					<div class="h4 font-weight-normal text-uppercase my-md-0 ">
					 la taille de box <strong>{{libtaille}}</strong>
					</div>
					<div class="h4 font-weight-normal text-uppercase my-md-0 ">
					 le box <strong>{{libsoustaille}}</strong>
					</div>
				</div>
				<hr>
				<div>
					<b-card-group deck>
						<b-card 
							v-for="(soustailleitem2, UI) in soustaille2" :key="UI"
							:bg-variant="soustailleitem2.itype !== 1 ? 'light' : 'white'"
							:border-variant="soustailleitem2.itype === 1 ? 'primary' : 'secondary'"
							:class="soustailleitem2.itype !== 1 ? 'mt-2' : 'mt-0'"
							class="text-center"
						>
							<b-card-text 
								v-if="soustailleitem2.itype === 0"
							>
								Plus petit
							</b-card-text>
							<b-card-text 
								v-if="soustailleitem2.itype === 1"
								class="h4 font-weight-normal text-uppercase my-md-0"
							>
								<strong>Votre choix</strong>
							</b-card-text>
							<b-card-text 
								v-if="soustailleitem2.itype === 2"
							>
								Plus grand
							</b-card-text>
							<b-card-body>
								<b-img
									center
									fluid
									:src="soustailleitem2.image"
								/>
							</b-card-body>
							<b-card-text >
								<hr>
								{{soustailleitem2.text}}
							</b-card-text>
							<b-card-text 
								v-if="soustailleitem2.Resulttarif.length !== 0">
								<hr>
								<div class="h1 font-weight-bolder text-secondary my-md-0 ">
									Choisir vos options :
								</div>
								<br>
								<div 
									 v-for="(tarifitem, UI) in soustailleitem2.Resulttarif" :key="UI"
								>	
									<b-button 
										v-if="tarifitem.nbbox !== 0"
										v-on:click="selecttarif(tarifitem,soustailleitem2)"
										variant="secondary"
										block
										>
										{{tarifitem.libelle}} : {{tarifitem.I_tarifttc}} ({{tarifitem.nbbox}})
									</b-button>
									<b-button 
										v-if="tarifitem.nbbox === 0"
										v-on:click="selecttarif(tarifitem)"
										variant="outline-secondary"
										block
										>
										{{tarifitem.libelle}} : {{tarifitem.I_tarifttc}} ({{tarifitem.nbbox}})
										<div> 
											Plus de disponibilté : Être mis sur liste d'attente 	
										</div>
									</b-button>
									<br>
								</div>	
							</b-card-text>
							<b-card-text 
								v-if="soustailleitem2.Resulttarif.length === 0">
								<hr>
								<strong>Désolé, nous n'avons plus de disponibilité</strong><br><br>
									<b-button 
										v-on:click="gocontact()"
										variant="outline-secondary"
										block
										>
										Être mis sur liste d'attente 
									</b-button>
							</b-card-text>
						</b-card>
					</b-card-group>
				</div>
				<div class="text-center">
					L'Accès Piéton vous permet de garer votre véhicule sur des places à proximité votre Box.<br>
					L'Accès Drive vous permet de garer votre véhicule devant votre Box.
					
				</div>
				<hr>
				<b-row offset-md="4" >
					<b-col class="text-left">
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							type="reset"
							variant="outline-secondary"
							@click="etapeencour=3"
						>
						  Precedent 
						</b-button>
					</b-col>
					<b-col class="text-right">

					</b-col>
				</b-row>
			</b-card>
			<b-card>
				<b-progress
					:max="maxetape"
					animated
					variant="secondary"
				>	
				<b-progress-bar :value="etapeencour" class="demo-vertical-spacing">
					  <strong> Etape {{ etapeencour }} sur {{ maxetape }}</strong>
					</b-progress-bar>
				</b-progress>
			</b-card>
		<small class="text-muted">{{ version_resa }}</small>
    </div>
  </div>
</template>
<script>
import { BCard, BButton, BProgress, BProgressBar, BImg, BRow, BCol, BFormInput, BFormCheckbox, BForm, BFormGroup, BInputGroup, BInputGroupPrepend, BCardText, BCardGroup, BCardFooter, BCardBody } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useRouter } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
	
export default {
	components: {
		BCard,
		BButton,
		BProgress, 
		BProgressBar, 
		BImg,
		BRow, 
		BCol,
		BFormInput,
		BFormCheckbox, 
		BForm,
		BFormGroup,
		BInputGroup,
		BInputGroupPrepend,
		BCardText,
		BCardGroup,
		BCardBody,
		BCardFooter,
		vSelect,
	},
	directives: {
		Ripple,
	},
	data() {
		const now = new Date()
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
		const minDate = new Date(today)
		return {
			version_resa: 'V1.0.0',
			bsoc: false,
			CRS: '',
			CSIRET: '',
			CTVA: '',
			CNOM: '',
			CPRENOM: '',
			CADRESSE: '',
			CCP: '',
			CVILLE: '',
			CPORT: '',
			CEMAIL: '',
			CCIVILITE: '',
			CCODE: '',
			bcode: false,
			bcodeok: false,
			MMESS: '',
			etapeencour: 1,
			maxetape: 3,
			min: minDate,
			max: minDate,
			taille: [],
			soustaille: [],
			soustaille2: [],
			soustaillearif1: [],
			soustaillearif2: [],
			soustaillearif3: [],
			tarifshoisi: [],
			soustaillechoixfinal: [],
			libsoc: '',
			libtaille: '',
			libui: '',
			libsoustaille: '',
			libsoustailleui: '',
			C_assurance: '',
			selectedassurance: null,
			assurance: [],
			selectedpays: { name: 'Réunion', code: 'RE' },
			civilite: [{
					name: 'NC',
					id: '1',
				},
				{
					name: 'Monsieur',
					id: '4',
				},
				{
					name: 'Madame',
					id: '2',
				},
				{
					name: 'Mademoiselle',
					id: '3',
				},
			],
			selectcivilite: { name: 'NC', id: '1' },
			pays: [{
				name: 'Afghanistan',
				code: 'AF',
			}, {
				name: 'Åland Islands',
				code: 'AX',
			}, {
				name: 'Albania',
				code: 'AL',
			}, {
				name: 'Algeria',
				code: 'DZ',
			}, {
				name: 'American Samoa',
				code: 'AS',
			}, {
				name: 'Andorra',
				code: 'AD',
			}, {
				name: 'Angola',
				code: 'AO',
			}, {
				name: 'Anguilla',
				code: 'AI',
			}, {
				name: 'Antarctica',
				code: 'AQ',
			}, {
				name: 'Antigua and Barbuda',
				code: 'AG',
			}, {
				name: 'Argentina',
				code: 'AR',
			}, {
				name: 'Armenia',
				code: 'AM',
			}, {
				name: 'Aruba',
				code: 'AW',
			}, {
				name: 'Australia',
				code: 'AU',
			}, {
				name: 'Austria',
				code: 'AT',
			}, {
				name: 'Azerbaijan',
				code: 'AZ',
			}, {
				name: 'Bahamas',
				code: 'BS',
			}, {
				name: 'Bahrain',
				code: 'BH',
			}, {
				name: 'Bangladesh',
				code: 'BD',
			}, {
				name: 'Barbados',
				code: 'BB',
			}, {
				name: 'Belarus',
				code: 'BY',
			}, {
				name: 'Belgium',
				code: 'BE',
			}, {
				name: 'Belize',
				code: 'BZ',
			}, {
				name: 'Benin',
				code: 'BJ',
			}, {
				name: 'Bermuda',
				code: 'BM',
			}, {
				name: 'Bhutan',
				code: 'BT',
			}, {
				name: 'Bolivia, Plurinational State of',
				code: 'BO',
			}, {
				name: 'Bonaire, Sint Eustatius and Saba',
				code: 'BQ',
			}, {
				name: 'Bosnia and Herzegovina',
				code: 'BA',
			}, {
				name: 'Botswana',
				code: 'BW',
			}, {
				name: 'Bouvet Island',
				code: 'BV',
			}, {
				name: 'Brazil',
				code: 'BR',
			}, {
				name: 'British Indian Ocean Territory',
				code: 'IO',
			}, {
				name: 'Brunei Darussalam',
				code: 'BN',
			}, {
				name: 'Bulgaria',
				code: 'BG',
			}, {
				name: 'Burkina Faso',
				code: 'BF',
			}, {
				name: 'Burundi',
				code: 'BI',
			}, {
				name: 'Cambodia',
				code: 'KH',
			}, {
				name: 'Cameroon',
				code: 'CM',
			}, {
				name: 'Canada',
				code: 'CA',
			}, {
				name: 'Cape Verde',
				code: 'CV',
			}, {
				name: 'Cayman Islands',
				code: 'KY',
			}, {
				name: 'Central African Republic',
				code: 'CF',
			}, {
				name: 'Chad',
				code: 'TD',
			}, {
				name: 'Chile',
				code: 'CL',
			}, {
				name: 'China',
				code: 'CN',
			}, {
				name: 'Christmas Island',
				code: 'CX',
			}, {
				name: 'Cocos (Keeling) Islands',
				code: 'CC',
			}, {
				name: 'Colombia',
				code: 'CO',
			}, {
				name: 'Comoros',
				code: 'KM',
			}, {
				name: 'Congo',
				code: 'CG',
			}, {
				name: 'Congo, the Democratic Republic of the',
				code: 'CD',
			}, {
				name: 'Cook Islands',
				code: 'CK',
			}, {
				name: 'Costa Rica',
				code: 'CR',
			}, {
				name: 'Côte d\'Ivoire',
				code: 'CI',
			}, {
				name: 'Croatia',
				code: 'HR',
			}, {
				name: 'Cuba',
				code: 'CU',
			}, {
				name: 'Curaçao',
				code: 'CW',
			}, {
				name: 'Cyprus',
				code: 'CY',
			}, {
				name: 'Czech Republic',
				code: 'CZ',
			}, {
				name: 'Denmark',
				code: 'DK',
			}, {
				name: 'Djibouti',
				code: 'DJ',
			}, {
				name: 'Dominica',
				code: 'DM',
			}, {
				name: 'Dominican Republic',
				code: 'DO',
			}, {
				name: 'Ecuador',
				code: 'EC',
			}, {
				name: 'Egypt',
				code: 'EG',
			}, {
				name: 'El Salvador',
				code: 'SV',
			}, {
				name: 'Equatorial Guinea',
				code: 'GQ',
			}, {
				name: 'Eritrea',
				code: 'ER',
			}, {
				name: 'Estonia',
				code: 'EE',
			}, {
				name: 'Ethiopia',
				code: 'ET',
			}, {
				name: 'Falkland Islands (Malvinas)',
				code: 'FK',
			}, {
				name: 'Faroe Islands',
				code: 'FO',
			}, {
				name: 'Fiji',
				code: 'FJ',
			}, {
				name: 'Finland',
				code: 'FI',
			}, {
				name: 'France',
				code: 'FR',
			}, {
				name: 'French Guiana',
				code: 'GF',
			}, {
				name: 'French Polynesia',
				code: 'PF',
			}, {
				name: 'French Southern Territories',
				code: 'TF',
			}, {
				name: 'Gabon',
				code: 'GA',
			}, {
				name: 'Gambia',
				code: 'GM',
			}, {
				name: 'Georgia',
				code: 'GE',
			}, {
				name: 'Germany',
				code: 'DE',
			}, {
				name: 'Ghana',
				code: 'GH',
			}, {
				name: 'Gibraltar',
				code: 'GI',
			}, {
				name: 'Greece',
				code: 'GR',
			}, {
				name: 'Greenland',
				code: 'GL',
			}, {
				name: 'Grenada',
				code: 'GD',
			}, {
				name: 'Guadeloupe',
				code: 'GP',
			}, {
				name: 'Guam',
				code: 'GU',
			}, {
				name: 'Guatemala',
				code: 'GT',
			}, {
				name: 'Guernsey',
				code: 'GG',
			}, {
				name: 'Guinea',
				code: 'GN',
			}, {
				name: 'Guinea-Bissau',
				code: 'GW',
			}, {
				name: 'Guyana',
				code: 'GY',
			}, {
				name: 'Haiti',
				code: 'HT',
			}, {
				name: 'Heard Island and McDonald Islands',
				code: 'HM',
			}, {
				name: 'Holy See (Vatican City State)',
				code: 'VA',
			}, {
				name: 'Honduras',
				code: 'HN',
			}, {
				name: 'Hong Kong',
				code: 'HK',
			}, {
				name: 'Hungary',
				code: 'HU',
			}, {
				name: 'Iceland',
				code: 'IS',
			}, {
				name: 'India',
				code: 'IN',
			}, {
				name: 'Indonesia',
				code: 'ID',
			}, {
				name: 'Iran, Islamic Republic of',
				code: 'IR',
			}, {
				name: 'Iraq',
				code: 'IQ',
			}, {
				name: 'Ireland',
				code: 'IE',
			}, {
				name: 'Isle of Man',
				code: 'IM',
			}, {
				name: 'Israel',
				code: 'IL',
			}, {
				name: 'Italy',
				code: 'IT',
			}, {
				name: 'Jamaica',
				code: 'JM',
			}, {
				name: 'Japan',
				code: 'JP',
			}, {
				name: 'Jersey',
				code: 'JE',
			}, {
				name: 'Jordan',
				code: 'JO',
			}, {
				name: 'Kazakhstan',
				code: 'KZ',
			}, {
				name: 'Kenya',
				code: 'KE',
			}, {
				name: 'Kiribati',
				code: 'KI',
			}, {
				name: 'Korea, Democratic People\'s Republic of',
				code: 'KP',
			}, {
				name: 'Korea, Republic of',
				code: 'KR',
			}, {
				name: 'Kuwait',
				code: 'KW',
			}, {
				name: 'Kyrgyzstan',
				code: 'KG',
			}, {
				name: 'Lao People\'s Democratic Republic',
				code: 'LA',
			}, {
				name: 'Latvia',
				code: 'LV',
			}, {
				name: 'Lebanon',
				code: 'LB',
			}, {
				name: 'Lesotho',
				code: 'LS',
			}, {
				name: 'Liberia',
				code: 'LR',
			}, {
				name: 'Libya',
				code: 'LY',
			}, {
				name: 'Liechtenstein',
				code: 'LI',
			}, {
				name: 'Lithuania',
				code: 'LT',
			}, {
				name: 'Luxembourg',
				code: 'LU',
			}, {
				name: 'Macao',
				code: 'MO',
			}, {
				name: 'Macedonia, the Former Yugoslav Republic of',
				code: 'MK',
			}, {
				name: 'Madagascar',
				code: 'MG',
			}, {
				name: 'Malawi',
				code: 'MW',
			}, {
				name: 'Malaysia',
				code: 'MY',
			}, {
				name: 'Maldives',
				code: 'MV',
			}, {
				name: 'Mali',
				code: 'ML',
			}, {
				name: 'Malta',
				code: 'MT',
			}, {
				name: 'Marshall Islands',
				code: 'MH',
			}, {
				name: 'Martinique',
				code: 'MQ',
			}, {
				name: 'Mauritania',
				code: 'MR',
			}, {
				name: 'Mauritius',
				code: 'MU',
			}, {
				name: 'Mayotte',
				code: 'YT',
			}, {
				name: 'Mexico',
				code: 'MX',
			}, {
				name: 'Micronesia, Federated States of',
				code: 'FM',
			}, {
				name: 'Moldova, Republic of',
				code: 'MD',
			}, {
				name: 'Monaco',
				code: 'MC',
			}, {
				name: 'Mongolia',
				code: 'MN',
			}, {
				name: 'Montenegro',
				code: 'ME',
			}, {
				name: 'Montserrat',
				code: 'MS',
			}, {
				name: 'Morocco',
				code: 'MA',
			}, {
				name: 'Mozambique',
				code: 'MZ',
			}, {
				name: 'Myanmar',
				code: 'MM',
			}, {
				name: 'Namibia',
				code: 'NA',
			}, {
				name: 'Nauru',
				code: 'NR',
			}, {
				name: 'Nepal',
				code: 'NP',
			}, {
				name: 'Netherlands',
				code: 'NL',
			}, {
				name: 'New Caledonia',
				code: 'NC',
			}, {
				name: 'New Zealand',
				code: 'NZ',
			}, {
				name: 'Nicaragua',
				code: 'NI',
			}, {
				name: 'Niger',
				code: 'NE',
			}, {
				name: 'Nigeria',
				code: 'NG',
			}, {
				name: 'Niue',
				code: 'NU',
			}, {
				name: 'Norfolk Island',
				code: 'NF',
			}, {
				name: 'Northern Mariana Islands',
				code: 'MP',
			}, {
				name: 'Norway',
				code: 'NO',
			}, {
				name: 'Oman',
				code: 'OM',
			}, {
				name: 'Pakistan',
				code: 'PK',
			}, {
				name: 'Palau',
				code: 'PW',
			}, {
				name: 'Palestine, State of',
				code: 'PS',
			}, {
				name: 'Panama',
				code: 'PA',
			}, {
				name: 'Papua New Guinea',
				code: 'PG',
			}, {
				name: 'Paraguay',
				code: 'PY',
			}, {
				name: 'Peru',
				code: 'PE',
			}, {
				name: 'Philippines',
				code: 'PH',
			}, {
				name: 'Pitcairn',
				code: 'PN',
			}, {
				name: 'Poland',
				code: 'PL',
			}, {
				name: 'Portugal',
				code: 'PT',
			}, {
				name: 'Puerto Rico',
				code: 'PR',
			}, {
				name: 'Qatar',
				code: 'QA',
			}, {
				name: 'Réunion',
				code: 'RE',
			}, {
				name: 'Romania',
				code: 'RO',
			}, {
				name: 'Russian Federation',
				code: 'RU',
			}, {
				name: 'Rwanda',
				code: 'RW',
			}, {
				name: 'Saint Barthélemy',
				code: 'BL',
			}, {
				name: 'Saint Helena, Ascension and Tristan da Cunha',
				code: 'SH',
			}, {
				name: 'Saint Kitts and Nevis',
				code: 'KN',
			}, {
				name: 'Saint Lucia',
				code: 'LC',
			}, {
				name: 'Saint Martin (French part)',
				code: 'MF',
			}, {
				name: 'Saint Pierre and Miquelon',
				code: 'PM',
			}, {
				name: 'Saint Vincent and the Grenadines',
				code: 'VC',
			}, {
				name: 'Samoa',
				code: 'WS',
			}, {
				name: 'San Marino',
				code: 'SM',
			}, {
				name: 'Sao Tome and Principe',
				code: 'ST',
			}, {
				name: 'Saudi Arabia',
				code: 'SA',
			}, {
				name: 'Senegal',
				code: 'SN',
			}, {
				name: 'Serbia',
				code: 'RS',
			}, {
				name: 'Seychelles',
				code: 'SC',
			}, {
				name: 'Sierra Leone',
				code: 'SL',
			}, {
				name: 'Singapore',
				code: 'SG',
			}, {
				name: 'Sint Maarten (Dutch part)',
				code: 'SX',
			}, {
				name: 'Slovakia',
				code: 'SK',
			}, {
				name: 'Slovenia',
				code: 'SI',
			}, {
				name: 'Solomon Islands',
				code: 'SB',
			}, {
				name: 'Somalia',
				code: 'SO',
			}, {
				name: 'South Africa',
				code: 'ZA',
			}, {
				name: 'South Georgia and the South Sandwich Islands',
				code: 'GS',
			}, {
				name: 'South Sudan',
				code: 'SS',
			}, {
				name: 'Spain',
				code: 'ES',
			}, {
				name: 'Sri Lanka',
				code: 'LK',
			}, {
				name: 'Sudan',
				code: 'SD',
			}, {
				name: 'Suriname',
				code: 'SR',
			}, {
				name: 'Svalbard and Jan Mayen',
				code: 'SJ',
			}, {
				name: 'Swaziland',
				code: 'SZ',
			}, {
				name: 'Sweden',
				code: 'SE',
			}, {
				name: 'Switzerland',
				code: 'CH',
			}, {
				name: 'Syrian Arab Republic',
				code: 'SY',
			}, {
				name: 'Taiwan, Province of China',
				code: 'TW',
			}, {
				name: 'Tajikistan',
				code: 'TJ',
			}, {
				name: 'Tanzania, United Republic of',
				code: 'TZ',
			}, {
				name: 'Thailand',
				code: 'TH',
			}, {
				name: 'Timor-Leste',
				code: 'TL',
			}, {
				name: 'Togo',
				code: 'TG',
			}, {
				name: 'Tokelau',
				code: 'TK',
			}, {
				name: 'Tonga',
				code: 'TO',
			}, {
				name: 'Trinidad and Tobago',
				code: 'TT',
			}, {
				name: 'Tunisia',
				code: 'TN',
			}, {
				name: 'Turkey',
				code: 'TR',
			}, {
				name: 'Turkmenistan',
				code: 'TM',
			}, {
				name: 'Turks and Caicos Islands',
				code: 'TC',
			}, {
				name: 'Tuvalu',
				code: 'TV',
			}, {
				name: 'Uganda',
				code: 'UG',
			}, {
				name: 'Ukraine',
				code: 'UA',
			}, {
				name: 'United Arab Emirates',
				code: 'AE',
			}, {
				name: 'United Kingdom',
				code: 'GB',
			}, {
				name: 'United States',
				code: 'US',
			}, {
				name: 'United States Minor Outlying Islands',
				code: 'UM',
			}, {
				name: 'Uruguay',
				code: 'UY',
			}, {
				name: 'Uzbekistan',
				code: 'UZ',
			}, {
				name: 'Vanuatu',
				code: 'VU',
			}, {
				name: 'Venezuela, Bolivarian Republic of',
				code: 'VE',
			}, {
				name: 'Viet Nam',
				code: 'VN',
			}, {
				name: 'Virgin Islands, British',
				code: 'VG',
			}, {
				name: 'Virgin Islands, U.S.',
				code: 'VI',
			}, {
				name: 'Wallis and Futuna',
				code: 'WF',
			}, {
				name: 'Western Sahara',
				code: 'EH',
			}, {
				name: 'Yemen',
				code: 'YE',
			}, {
				name: 'Zambia',
				code: 'ZM',
			}, {
				name: 'Zimbabwe',
				code: 'ZW',
			}],
		}
	},
	created() {
		const UISOC = this.UI
		this.bcodeok = false
		this.$http.get(`${this.APIURL}resa/gettaille/${UISOC}`)
		.then(response => {
			if (response.data.res === 1) {
				this.taille = response.data.ResultTailleBox
				this.libsoc = response.data.libelle
				const numberOfDaysToAdd = 15
				const now = new Date()
				const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
				const maxDate = new Date(today)
				// maxDate.setMonth(maxDate.getMonth() + 2)
				maxDate.setDate(maxDate.getDate() + numberOfDaysToAdd)
				this.max = maxDate
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : demande non trouvée',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})
			}
		}) 
	},
	setup() {
		const { router } = useRouter()	
		const UI = router.currentRoute.params.id
		const APIURL = 'https://api.reunibox.com/'
		return {
			UI,
			APIURL,
		}
	},
	methods: {
		selecttaille: function (taillechoisie) {   
			this.libtaille = taillechoisie.libelle
			this.libui = taillechoisie.UI
			this.$http.get(`${this.APIURL}resa/getsoustaille/${taillechoisie.UI}`)
			.then(response => {
				if (response.data.res === 1) {
				this.soustaille = response.data.ResultSousTailleBox
				this.etapeencour = 2
			} else {
				this.$toast({
				component: ToastificationContent,
				props: {
					title: 'Oups',
					icon: 'SmileIcon',
					variant: 'primary',
					text: 'Erreur : demande non trouvée',
				},
				},
				{
				timeout: 4000,
				position: 'top-center',
				})
			}
			}) 
		},
		selectsoustaille: function (taillechoisie) {   
			this.libsoustaille = taillechoisie.libelle
			this.libsoustailleui = taillechoisie.UI
			this.etapeencour = 3
			let isoustaille = 0
			this.soustaille2 = []
			this.soustaillearif1 = []
			this.soustaillearif2 = []
			this.soustaillearif3 = []
			for (isoustaille = 0; isoustaille < this.soustaille.length; isoustaille += 1) {
				if (this.soustaille[isoustaille].UI === this.libsoustailleui) {
					if (isoustaille > 0) {
						// this.soustaille2.push(this.soustaille[isoustaille - 1])	
						this.soustaille2.push({
							UI: this.soustaille[isoustaille - 1].UI,
							libelle: this.soustaille[isoustaille - 1].libelle,
							image: this.soustaille[isoustaille - 1].image,
							text: this.soustaille[isoustaille - 1].text,
							nbbox: this.soustaille[isoustaille - 1].nbbox,
							Resulttarif: this.soustaille[isoustaille - 1].Resulttarif,
							itype: 0,
						})
					}
					// this.soustaille2.push(this.soustaille[isoustaille])
					this.soustaille2.push({
						UI: this.soustaille[isoustaille].UI,
						libelle: this.soustaille[isoustaille].libelle,
						image: this.soustaille[isoustaille].image,
						text: this.soustaille[isoustaille].text,
						nbbox: this.soustaille[isoustaille].nbbox,
						Resulttarif: this.soustaille[isoustaille].Resulttarif,
						itype: 1,
					})
					if (isoustaille < (this.soustaille.length - 1)) {
						// this.soustaille2.push(this.soustaille[isoustaille + 1])	
						this.soustaille2.push({
							UI: this.soustaille[isoustaille + 1].UI,
							libelle: this.soustaille[isoustaille + 1].libelle,
							image: this.soustaille[isoustaille + 1].image,
							text: this.soustaille[isoustaille + 1].text,
							nbbox: this.soustaille[isoustaille + 1].nbbox,
							Resulttarif: this.soustaille[isoustaille + 1].Resulttarif,
							itype: 2,
						})
					}
				} 
			}
		},
		goetape4() {
			let berr = false
			// let UIASS = ''
			this.totalmensuel = 0 // parseFloat(this.I_prixTTC) + parseFloat(this.ttcassurance)
			if ((this.CPORT === '') || (this.CEMAIL === '')) {
				berr = true	
			}
			if (this.bsoc) {
				if (this.CRS === '') {
					berr = true	
				}
			}
			if (!berr) {
				// this.libdatedebut = this.datedebut.split('-').reverse().join('/')
				// this.etapeencour = 4	
				this.$http.post(`${this.APIURL}resa/adddevis`, { UISOC: this.UI, EMAIL: this.CEMAIL, PORT: this.CPORT, CNOM: this.CNOM, CPRENOM: this.CPRENOM, CADRESSE: this.CADRESSE, CCP: this.CCP, CVILLE: this.CVILLE, CPAYS: this.selectedpays.name, CPAYSCODE: this.selectedpays.code, BSOC: this.bsoc, CRS: this.CRS, CSIRET: this.CSIRET, CTVA: this.CTVA, taillechoisielibelle: this.libtaille, taillechoisieUI: this.libui, soustaillechoisielibelle: this.libsoustaille, soustaillechoisieUI: this.libsoustailleui, DATEDEBUT: this.datedebut, CCIV: this.selectcivilite.id, CCILLIB: this.selectcivilite.name })
					.then(response => {
						if (response.data.res === 1) {
							const url = new URL(`/resa2/${response.data.UIdemande}`, window.location.origin)
							window.location.href = url.toString()
							// this.$router.push({ name: `/resa2/${response.data.UIdemande}` })
							// this.etapeencour = 4	
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Oups',
									icon: 'SmileIcon',
									variant: 'primary',
									text: 'Erreur : Erreur de connexion du serveur',
								},
							},
							{
								timeout: 4000,
								position: 'top-center',
							})	
						}	
					}) 
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : Merci de saisir tout les champs',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})		
			}
		},	
		selecttarif: function (tarifchoisi, soustailleitem2) {
				let berr2 = false
				if (tarifchoisi === '') {
					berr2 = true	
				}
				if (!berr2) {
					this.tarifshoisi = tarifchoisi
					this.soustaillechoixfinal = soustailleitem2
					this.etapeencour = 5	
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Merci de saisir tout les champs',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})		
				}
		},	
	},
}
</script>
<style lang="scss">
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
   opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.20);
  }
  100% {
    transform: scale(1);
  }
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
